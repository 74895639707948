import React, {useState} from "react";
import Stars from "./stars";
const ChamberReviews = () =>{

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }
        
    const reviewsChamb = [
        {
            name:"Geiling Rodriguez",
            date:"3 weeks ago",
            text:"Muy buen trabajo me ha encantado mi nueva cocina. Muy buena compañía esa. Gracias por su excelente trabajo y son muy rápidos a las pocas semana ya tenia mi cocina hermosa. Se los recomiendo no se van a arrepentir",
            stars:5
        },
        {
            name:"Raywill Roque",
            date:"2 years ago",
            text:"👍👌🤩…",
            stars:5
        },
        {
            name:"Carlos Garzon",
            date:"A month ago",
            text:"As a contractor , I have been using YRM granite for over 5 years and every single job has been more than outstanding, from the customer service to the craftsmanship and professionalism of the installation crew is a pleasure to work with a company that takes pride on what they do. YRM is a company that focus on giving their clients the best options to make their homes look beautiful.",
            stars:5
        },
        {
            name:"John Battersby",
            date:"A month ago",
            text:"We have used YRM granite for two jobs - we have recently remodeled our kitchen and a guest bathroom.  We love our countertops in both spaces!  YRM has been very responsive to communication and very easy to schedule. Thank you!",
            stars:5
        },
        {
            name:"Elias Y",
            date:"A month ago",
            text:"I have to say im very happy and amazed with the work YRM has done in my house.  Amazing service , they have a lot of knowledge and passion for their work . Remberto the owner has a lot of knowledge and ideas that help me build the dream kitchen  and bathroom , a complete service and at a good price. The details on their work it’s incredible and the time they took from the moment we spoke about measurements to the time they finished was pretty fast . Other companies I’ve talk to has gave me a longer time frame to finish the work As if YRM prioritize Quality of Work . Very happy honestly with everything done in my house. Definitely 5 Starts 🙏🏻",
            stars:5
        },
        {
            name:"Lancelot Graham",
            date:"A month ago",
            text:"YRM Granite is reliable, efficient, affordable, and professional.  When they give you a time frame for installation of countertop, you know they are going to meet that time frame.  YRM Granite is professional, the finished product is perfectly installed according to specifications and the customers are satisfied. Also, their products are reasonably priced. Majestic Flooring and Renovations LLC is a satisfied customer of YRM Granite.",
            stars:5
        },
        {
            name:"Yunaisy Gonzalez",
            date:"4 years ago",
            text:"Best Granite counters of West Palm Beach!! 💙💙",
            stars:5
        },
        {
            name:"Robe S",
            date:"A month ago",
            text:"They did an awesome job on my kitchen! Very professional and honest!",
            stars:5
        },
        {
            name:"Yamila Machado",
            date:"A year ago",
            text:"Wonderful service and craftmanship. I recommend them wholeheartedly. Rembert is very knowledgeable. My kitchen looks fantastic. Thank you YRM!!!",
            stars:5
        },
    ]

    return(
        <section className="w-full py-20">
            <h2 className="text-center">Our Reviews</h2>
                <a href="https://maps.app.goo.gl/6MRvZ25Tv4xGgV2x5"
                target="_blank"
                rel="noreferrer"
                className="flex w-[300px] mx-auto justify-center">
                    <img
                    alt="Logo-GMB"
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fgmb.png?alt=media&token=60a1d993-6244-4652-9bc1-a7cc7332a2d5"
                   />
                </a> 
                

                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium">{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button>
        </section> 
    );

}

export default ChamberReviews